import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, IconButton, Stack, AppBar, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import conObj from '../../../utils/connection-assistant';
import NotificationsPopover from '../../../views/games/NotificationPopover';
import { useDispatch, useSelector } from '../../../redux/store';
import { updateAccount } from '../../../redux/slices/account';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  display: 'block',
  boxShadow: 'none',
  position: 'inherit !important',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '#3E78B2',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    // ...(isCollapse && {
    //   width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    // }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    // ...(verticalLayout && {
    //   width: '100%',
    //   height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    //   backgroundColor: theme.palette.background.default,
    // }),
  },
}));

const RooToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { balance, notifs } = useSelector((state) => state.account);

  const [playerInfo, setPlayerInfo] = useState({});
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    getPlayerInfo();

    const interval = setInterval(() => {
      getPlayerInfo();
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const getPlayerInfo = () => {
    conObj.get('Player/Me').then((res) => {
      if (res.status === 200) {
        setPlayerInfo(res.data);
        const { balance, playerName, notifs } = res.data;
        handleUpdateAccount({ balance, notifs });
        localStorage.setItem('playerName', playerName);
      }
    });
  };

  const handleUpdateAccount = (accInfo) => {
    dispatch(updateAccount(accInfo));
  };

  const readNotifications = () => {
    conObj.get('Player/ReadNotifs').then((res) => {
      if (res.status === 200) {
        //
      }
    });
  };

  return (
    <>
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
        <RooToolbar
          sx={{
            minHeight: '100% !important',
            // px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {/* {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="noto-v1:money-bag" />
          </IconButtonAnimate>
        )} */}
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Iconify icon="noto-v1:money-bag" height="25px" width="25px" sx={{ marginTop: '-5px' }} />
            <Typography
              sx={{
                marginLeft: '5px !important',
                padding: '2px 5px 0px',
                background: 'white',
                borderRadius: '5px',
                color: '#3E78B2',
                fontWeight: 700,
              }}
            >
              {balance}
            </Typography>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Welcome Back: </span>
              {`${playerInfo.playerName}`}
            </Typography>
            {notifs && <NotificationsPopover notifications={notifs} readNotifications={readNotifications} />}
          </Stack>
        </RooToolbar>
      </RootStyle>
    </>
  );
}
