import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { login } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const isMountedRef = useIsMountedRef();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    const accessToken = searchParams.get('token');
    if (accessToken) {
      if (searchParams.get('game')) {
        sessionStorage.setItem('gameCode', searchParams.get('game'));
      }
      const loginData = {
        user: {},
        accessToken,
      };
      onSubmit(loginData);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      await login(data.user, data.accessToken);
    } catch (error) {
      console.error(error);

      if (isMountedRef.current) {
        console.log(`error.message = ${error.message}`);
        // setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <Page title="Landing">
      <RootStyle>
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img alt="404 icon" src={require('../../assets/dead-skin.png')} style={{ height: '85px', width: 'auto' }} />
          <Box sx={{ mt: 2 }}>
            <Typography textAlign="center" variant="h4">
              404 Error
            </Typography>
            <Typography textAlign="center">Sorry, page not found</Typography>
          </Box>
        </Box>
      </RootStyle>
    </Page>
  );
}
