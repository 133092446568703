import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  balance: 0,
  notifs: [],
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAccount(state, action) {
      state.balance = action.payload.balance;
      state.notifs = action.payload.notifs;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateAccount } = slice.actions;
